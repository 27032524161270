<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:body>
            <b-overlay :show="loadingState">
              <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                <b-form  @submit.prevent="handleSubmit(saveData)" @reset.prevent="reset" >
                    <b-row>
                      <b-col lg="6" sm="12">
                        <ValidationProvider name="Organization Name" vid="organization_name" rules="required|min_value:1">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="organization_name"
                                slot-scope="{ valid, errors }"
                                >
                                <template v-slot:label>
                                {{ $t('research_manage.organization_name')}} <span class="text-danger">*</span>
                                </template>
                                <b-form-select
                                plain
                                v-model="research_program.organization_name"
                                :options="orgList"
                                id="organization_name"
                                :state="errors[0] ? false : (valid ? true : null)"
                                >
                                <template v-slot:first>
                                    <b-form-select-option :value="''" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                                </b-form-select>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                                </div>
                            </b-form-group>
                        </ValidationProvider>
                      </b-col>
                      <b-col lg="6" sm="12">
                          <ValidationProvider name="Program Name(En)" vid="program_name_en" rules="required">
                          <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="program_name_en"
                              slot-scope="{ valid, errors }"
                          >
                          <template v-slot:label>
                              {{$t('research_manage.program_name_en')}} <span class="text-danger">*</span>
                              </template>
                              <b-form-input
                              id="program_name_en"
                              v-model="research_program.program_name_en"
                              :state="errors[0] ? false : (valid ? true : null)"
                              ></b-form-input>
                              <div class="invalid-feedback">
                              {{ errors[0] }}
                              </div>
                          </b-form-group>
                          </ValidationProvider>
                      </b-col>
                      <b-col lg="6" sm="12">
                          <ValidationProvider name="Program Name(Bn)" vid="program_name_bn" rules="required">
                          <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="program_name_bn"
                              slot-scope="{ valid, errors }"
                          >
                          <template v-slot:label>
                              {{$t('research_manage.program_name_bn')}} <span class="text-danger">*</span>
                              </template>
                              <b-form-input
                              id="program_name_bn"
                              v-model="research_program.program_name_bn"
                              :state="errors[0] ? false : (valid ? true : null)"
                              ></b-form-input>
                              <div class="invalid-feedback">
                              {{ errors[0] }}
                              </div>
                          </b-form-group>
                          </ValidationProvider>
                      </b-col>
                      <b-col lg="6" sm="12">
                          <ValidationProvider name="Program Description(En)" vid="program_description_en" rules="required">
                          <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="program_description_en"
                              slot-scope="{ valid, errors }"
                              style="font-size: 13px;"
                          >
                          <template v-slot:label>
                              {{$t('research_manage.program_description_en')}} <span class="text-danger">*</span>
                              </template>
                              <b-form-textarea
                              id="program_description_en"
                              v-model="research_program.program_description_en"
                              :state="errors[0] ? false : (valid ? true : null)"
                              ></b-form-textarea>
                              <div class="invalid-feedback">
                              {{ errors[0] }}
                              </div>
                          </b-form-group>
                          </ValidationProvider>
                      </b-col>
                      <b-col lg="6" sm="12">
                          <ValidationProvider name="Program Description(Bn)" vid="program_description_bn" rules="required">
                          <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="program_description_bn"
                              slot-scope="{ valid, errors }"
                              style="font-size: 13px;"
                          >
                          <template v-slot:label>
                              {{$t('research_manage.program_description_bn')}} <span class="text-danger">*</span>
                              </template>
                              <b-form-textarea
                              id="program_description_bn"
                              v-model="research_program.program_description_bn"
                              :state="errors[0] ? false : (valid ? true : null)"
                              ></b-form-textarea>
                              <div class="invalid-feedback">
                              {{ errors[0] }}
                              </div>
                          </b-form-group>
                          </ValidationProvider>
                      </b-col>
                      <b-col lg="6" sm="12">
                          <ValidationProvider name="Budget" vid="budget" rules="required">
                          <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="budget"
                              slot-scope="{ valid, errors }"
                          >
                          <template v-slot:label>
                              {{$t('research_manage.budget')}} <span class="text-danger">*</span>
                              </template>
                              <b-form-input
                              id="budget"
                              v-model="research_program.budget"
                              :state="errors[0] ? false : (valid ? true : null)"
                              oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                              ></b-form-input>
                              <div class="invalid-feedback">
                              {{ errors[0] }}
                              </div>
                          </b-form-group>
                          </ValidationProvider>
                      </b-col>
                      <b-col lg="6" sm="12">
                          <ValidationProvider name="Program Start Date" vid="program_start" rules="required">
                          <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="program_start"
                              slot-scope="{ valid, errors }"
                          >
                          <template v-slot:label>
                              {{$t('research_manage.program_start')}} <span class="text-danger">*</span>
                              </template>
                                <flat-pickr class="form-control"
                                  v-model="research_program.program_start"
                                  id="to_date"
                                >
                                </flat-pickr>
                              <div class="invalid-feedback">
                              {{ errors[0] }}
                              </div>
                          </b-form-group>
                          </ValidationProvider>
                      </b-col>
                      <b-col lg="6" sm="12">
                          <ValidationProvider name="Program End Date" vid="program_end" rules="required">
                          <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="program_end"
                              slot-scope="{ valid, errors }"
                          >
                          <template v-slot:label>
                              {{$t('research_manage.program_end')}} <span class="text-danger">*</span>
                              </template>
                                <flat-pickr class="form-control"
                                  v-model="research_program.program_end"
                                  id="to_date"
                                >
                                </flat-pickr>
                              <div class="invalid-feedback">
                              {{ errors[0] }}
                              </div>
                          </b-form-group>
                          </ValidationProvider>
                      </b-col>
                    </b-row>
                    <div class="row">
                    <div class="col-sm-3"></div>
                    <div class="col text-right">
                        <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                        &nbsp;
                        <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-4')">{{ $t('globalTrans.cancel') }}</b-button>
                    </div>
                    </div>
                </b-form>
              </ValidationObserver>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '../../../../../../config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { agriResearchServiceBaseUrl } from '../../../../../../config/api_config'
import { ResearchProgramInfoStore, ResearchProgramInfoUpdate } from '../../../api/routes'
import researchTestingModal from '@/mixins/research-testing-modal'
export default {
  name: 'FormLayout',
  props: ['id'],
  mixins: [researchTestingModal],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  data () {
    return {
        org: [],
        saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
        research_program: {
        organization_name: '',
        program_name_en: '',
        program_name_bn: '',
        program_description_bn: '',
        program_description_en: '',
        budget: '',
        program_start: '',
        program_end: ''
        }
    }
  },
  computed: {
    sectorList: function () {
        return this.$store.state.AgriResearch.commonObj.sectorList
    },
    orgList: function () {
      return this.$store.state.commonObj.organizationProfileList.filter(item => item.status === 0)
    }
  },
  watch: {
  },
  created () {
    this.org = [
      { value: '2021', text: '2021' },
      { value: '2020', text: '2020' },
      { value: '2019', text: '2019' },
      { value: '2018', text: '2018' },
      { value: '2017', text: '2017' },
      { value: '2016', text: '2016' },
      { value: '2015', text: '2015' },
      { value: '2014', text: '2014' },
      { value: '2013', text: '2013' },
      { value: '2012', text: '2012' },
      { value: '2011', text: '2011' },
      { value: '2010', text: '2010' },
      { value: '2009', text: '2009' },
      { value: '2008', text: '2008' },
      { value: '2007', text: '2007' },
      { value: '2006', text: '2006' },
      { value: '2005', text: '2005' },
      { value: '2004', text: '2004' },
      { value: '2003', text: '2003' },
      { value: '2001', text: '2001' },
      { value: '2000', text: '2000' },
      { value: '1999', text: '1999' },
      { value: '1998', text: '1998' },
      { value: '1997', text: '1997' },
      { value: '1996', text: '1996' },
      { value: '1995', text: '1995' },
      { value: '1994', text: '1994' },
      { value: '1993', text: '1993' },
      { value: '1992', text: '1992' },
      { value: '1991', text: '1991' },
      { value: '1990', text: '1990' },
      { value: '1989', text: '1989' },
      { value: '1988', text: '1988' },
      { value: '1987', text: '1987' },
      { value: '1986', text: '1986' },
      { value: '1985', text: '1985' },
      { value: '1984', text: '1984' },
      { value: '1983', text: '1983' },
      { value: '1982', text: '1982' },
      { value: '1981', text: '1981' },
      { value: '1980', text: '1980' },
      { value: '1979', text: '1979' },
      { value: '1978', text: '1978' },
      { value: '1977', text: '1977' },
      { value: '1976', text: '1976' },
      { value: '1975', text: '1975' },
      { value: '1974', text: '1974' },
      { value: '1973', text: '1973' },
      { value: '1972', text: '1972' },
      { value: '1971', text: '1971' },
      { value: '1970', text: '1970' },
      { value: '1969', text: '1969' }
      ]
    this.month = [
      { value: 'january', text: 'January' },
      { value: 'february', text: 'February' },
      { value: 'march', text: 'March' },
      { value: 'april', text: 'April' },
      { value: 'may', text: 'May' },
      { value: 'june', text: 'June' },
      { value: 'july', text: 'July' },
      { value: 'august', text: 'August' },
      { value: 'september', text: 'September' },
      { value: 'october', text: 'October' },
      { value: 'novmber', text: 'Novmber' },
      { value: 'december', text: 'December' }
      ]
    if (this.id) {
        const tmp = this.getSubSectorData()
        // Object.freeze(tmp)
        this.research_program = tmp
    }
  },
  mounted () {
    core.index()
  },
  methods: {
    getSubSectorData () {
      const tmpData = this.$store.state.list.find(project => project.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    },
    async saveData () {
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null
      const loadingState = { loading: false, listReload: false }

      if (this.id) {
        result = await RestApi.putData(agriResearchServiceBaseUrl, `${ResearchProgramInfoUpdate}/${this.id}`, this.research_program)
      } else {
        result = await RestApi.postData(agriResearchServiceBaseUrl, ResearchProgramInfoStore, this.research_program)
      }
      loadingState.listReload = true
      this.$store.dispatch('mutateCommonProperties', loadingState)

      if (result.success) {
        this.$store.dispatch('AgriResearch/mutateCommonObj', { hasDropdownLoaded: false })
        this.$toast.success({
          title: 'Success',
          message: this.id ? 'Data updated successfully' : 'Data save successfully',
          color: '#D6E09B'
        })

        this.$bvModal.hide('modal-4')
      } else {
        this.$refs.form.setErrors(result.errors)
        this.$toast.error({
          title: 'Error',
          message: 'Operation failed! Please, try again.'
        })
      }
    }
  }
}
</script>
