<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:body>
            <b-row>
                <b-col md="12" class="table-responsive">
                  <b-table bordered hover :items="sub_sector" :fields="columns" aria-hidden="loading | listReload ? 'true' : null" :emptyText="$t('globalTrans.noDataFound')" show-empty>
                    <template v-slot:cell(index)="data">
                      {{ $n(data.index + 1) }}
                    </template>
                    <template v-slot:cell(sub_sector_name)="data">
                      <span class="capitalize">{{ data.item.sub_sector_name }}</span>
                    </template>
                    <template v-slot:cell(sub_sector_name_bn)="data">
                      <span class="capitalize">{{ data.item.sub_sector_name_bn }}</span>
                    </template>
                    <template v-slot:cell(sub_sector_description)="data">
                      <span class="capitalize">{{ data.item.sub_sector_description==="" ? 'N/A' : data.item.sub_sector_description }}</span>
                    </template>
                    <template v-slot:cell(sub_sector_description_bn)="data">
                      <span class="capitalize">{{ data.item.sub_sector_description_bn==="" ? 'N/A' : data.item.sub_sector_description_bn }}</span>
                    </template>
                    <template v-slot:cell(sector_code)="data">
                      <span class="capitalize">{{ data.item.sector_code }}</span>
                    </template>
                  </b-table>
                </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'

export default {
  props: ['item'],
  created () {
      const tmp = this.item
      this.sub_sector.push(tmp)
  },
  mounted () {
    core.index()
  },
  data () {
    return {
      sub_sector: []
    }
  },
  computed: {
    localeLang () {
      return this.$i18n.locale
    },
    listData () {
      const objectData = this.$store.state.IrriConfig.pumpProgressTypes
      return objectData.filter(data => data.org_id === this.item.org_id && data.pump_type_id === this.item.pump_type_id && data.application_type_id === this.item.application_type_id)
    },
    columns () {
        const labels = [
          { label: this.$t('research_manage.sector'), class: 'text-left' },
          { label: this.$t('research_manage.sub_sector_name'), class: 'text-left' },
          { label: this.$t('globalTrans.description'), class: 'text-left' }
        ]

      let keys = []

      if (this.$i18n.locale === 'bn') {
        keys = [
            { key: 'sector_name_bn' },
            { key: 'sub_sector_name_bn' },
            { key: 'sub_sector_description_bn' }
        ]
      } else {
        keys = [
            { key: 'sector_name_bn' },
            { key: 'sub_sector_name' },
            { key: 'sub_sector_description' }
        ]
      }
      return labels.map((item, index) => {
          return Object.assign(item, keys[index])
      })
    }
  },
  methods: {
  }
}
</script>
